angular.module('CaresApp').service('OcrAppointmentOfficeAccessRequestDialogService', [
  '$document',
  '$mdDialog',
  function($document, $mdDialog) {
    const self = this;
    self.show = function(appointment, request) {
      $mdDialog.show({
        controller: 'AppointmentOfficeAccessRequestDialogController',
        templateUrl: 'dialogs/ocr-appointment-office-access-request-dialog/'
          + 'ocr-appointment-office-access-request-dialog.template.html',
        parent: angular.element($document[0].body),
        locals: {
          appointment: appointment,
          request: request,
        },
        clickOutsideToClose: false,
        bindToController: true,
        multiple: true,
        controllerAs: '$ctrl',
      }).then(function(newRequest) {
        return newRequest;
      }, function() {});
    }
  },
]);
