angular.module('CaresApp').controller('InvoiceFlagsDialogController', [
  '$mdDialog',
  'OcrInvoiceFlagsService',
  function($mdDialog, OcrInvoiceFlagsService) {
    const self = this
    self.hoursFlag = OcrInvoiceFlagsService.getFlagInfo('hours')
    self.hoursWarningDisplayValue = self.hoursFlag.hoursWarning / 60
    self.hoursCriticalDisplayValue = self.hoursFlag.hoursCritical / 60

    self.hoursWarningValueChanged = () => self.hoursFlag.hoursWarning
      = self.hoursWarningDisplayValue * 60
    self.hoursCriticalValueChanged = () => self.hoursFlag.hoursCritical
      = self.hoursCriticalDisplayValue * 60

    self.hide = function() {
      $mdDialog.hide();
    };

    self.cancel = function() {
      $mdDialog.cancel();
    };


    self.close = function() {
      $mdDialog.hide();
    };
  },
]);
