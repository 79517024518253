angular.module('CaresApp').controller('AppointmentOfficeAccessRequestDialogController', [
  '$q',
  '$scope',
  '$document',
  '$mdDialog',
  '$mdToast',
  'AppointmentOfficeAccessRequestService',
  'AppointmentOfficeAccessRequestReasonService',
  'OcrLookups',
  'OcrAuth',
  function($q, $scope, $document, $mdDialog, $mdToast, AppointmentOfficeAccessRequestService,
    AppointmentOfficeAccessRequestReasonService, OcrLookups, OcrAuth,) {
    const self = this
    self.loading = true;
    const promises = [];
    const personasPromise = OcrLookups.get('Persona').then(
      (personas) => self.allPersonas = personas);
    $q.all([
      personasPromise,
      OcrLookups.get('PersonaApplicableTo').then(
        (applicableTo) => self.personaApplicableToOptions = applicableTo),
    ]).then(
      () => self.personas = self.allPersonas.filter(
        (persona) => self.personaApplicableToOptions
          .lookup(persona.applicableToId)
          .applicableTo
          .toLowerCase()
          .includes("office"),
      ),
    )

    self.isApprover = OcrAuth.checkPermission("ADMIN_WRITE");
    self.isRequester = OcrAuth.checkPermission("ADMIN_WRITE");
    self.status = null;
    self.checkPermission = OcrAuth.checkPermission;
    self.requestReasons = self.requestStatuses = self.offices = OcrLookups.dummyList;

    self.$onInit = function() {
      self.submitting = false;
      $scope.$watch(
        () => angular.isDefined(self.form),
        function() {
          $scope.$watch(
            () => self.form.$invalid,
            function() {
              angular.forEach(self.form.$error, function(field) {
                angular.forEach(field, function(errorField) {
                  errorField.$setTouched();
                });
              });
          }, true);
        }, true);

      if (!self.request) {
        self.request = {
          appointmentId: self.appointment.id,
        };
        self.title = "Request Appointment Access";
        self.isRequester = true;
      } else {
        self.title = "Appointment Access Request";
        self.isRequester = self.isRequester || self.request.requesterId === OcrAuth.session.user.id;
        self.isApprover = self.request.appointedAttorneyId === OcrAuth.session.user.id;
        self.request.currentStatusId = self.request.statusId;
        if (self.request.requestReasonId) {
          promises.push(AppointmentOfficeAccessRequestReasonService.get({
            id: self.request.requestReasonId
          }).$promise.then(function(response){
            console.log(response);
            self.request.reason = response.reason;
          }))
        }
        if (self.request.newPersonaId) {
          personasPromise.then(
            (personas) => self.request.persona = personas.lookup(self.request.newPersonaId),
          );
        }
      }

      OcrLookups.get('AppointmentOfficeAccessRequestStatus').then(
        (requestStatuses) => self.requestStatuses = requestStatuses,
      ).then(() => {
        if (self.request.statusId) {
          self.status = self.requestStatuses.lookup(self.request.statusId).status;
        }
      }).then(() => self.loading = false);

      OcrLookups.get('Office', true).then(
        (offices) => self.offices = offices,
      ).then(() => {
        if (self.request.requestedOfficeId) {
          self.office = self.offices.lookup(self.request.requestedOfficeId).name;
        }
      }).then(() => self.loading = false);

      promises.push(AppointmentOfficeAccessRequestReasonService.get({
        date: new Date(),
      }).$promise.then(function(resp){
        console.log(resp.list);
        self.requestReasons = resp.list;
      }));
    };

    self.emptyNotes = function() {
      if (angular.isUndefined(self.request.requestDetails)) {
        return true;
      }
      return false;
    }

    self.save = function(action) {
      $mdToast.show(
        $mdToast.simple()
          .textContent('Saving...')
          .position("bottom right"),
      );
      let saveFunction = null;
      let saveArgs = null;
      if (self.request.id) {
        saveFunction = AppointmentOfficeAccessRequestService.update;
        saveArgs = [{ id: self.request.id }, self.request];
      } else {
        saveFunction = AppointmentOfficeAccessRequestService.save;
        saveArgs = [self.request];
      }
      saveFunction.apply(this, saveArgs).$promise.then(
        function(response) {
          $mdToast.show(
            $mdToast.simple()
              .textContent(`Access request ${action}.`)
              .position("bottom right"),
          )
          self.close(response);
          self.submitting = false;
          $mdDialog.hide();
        },
      ).catch(function(error) {
        self.submitting = false;
        if (error && error.data && error.data.status === 401) {
          self.close();
          return;
        }
        if (error && error.data) {
          $mdToast.show(
            $mdToast.simple()
              .textContent("Update Failed: " + error.data.reason)
              .position("bottom right"),
          );
        }
      })
    }
    self.hide = $mdDialog.hide;
    self.cancel = $mdDialog.cancel;
    self.close = $mdDialog.hide;

    self.isNew = () => !self.status;
    self.canApproveDeny = () => self.status === "Pending"
      && self.isApprover
      && !self.request.revoked;
    self.canCancelRequest = () => self.status === "Pending"
      && self.isRequester
      && !self.request.revoked;
    self.canRevoke = () => self.status === "Approved"
      && self.isApprover
      && !self.request.revoked;

    self.canEditPersonaDropdown = () => self.status === "Pending" && self.isApprover;

    self.create = function() {
      if (!self.isNew()) {
        return;
      }
      self.request.statusId = self.requestStatuses.lookup("Pending", "status").id;
      self.save("created");
    };

    self.confirm = function() {
      if (!self.decision) {
        return;
      }

      if (self.decision == 1) {
        self.approve();
      } else if (self.decision == 0) {
        self.deny();
      }
    }

    self.approve = function() {
      if (!self.canApproveDeny()) {
        return;
      }
      const message =
        "Please confirm: Are you sure you want to approve this request for access "
        + "to this appointment? The requester will receive an automatic email "
        + "notification of your decision, including any Decision Notes you "
        + "provided. Please click Approve to confirm, or click Cancel to go back.";
      $mdDialog.show(
        $mdDialog.confirm()
          .parent(angular.element($document[0].querySelector(
            '#popupContainer')))
          .clickOutsideToClose(false)
          .title("Confirm Approve")
          .textContent(message)
          .ariaLabel(message)
          .ok("Approve")
          .cancel('Cancel')
          .multiple(true),
      ).then(function() {
        self.request.statusId = self.requestStatuses.lookup("Approved", "status").id;
        self.save("approved");
      },
      () => {},
      );
    };


    self.deny = function() {
      if (!self.canApproveDeny()) {
        return;
      }
      const message =
        "Please confirm: Are you sure you want to deny this request for access "
        + "to this appointment? The requester will receive an automatic email "
        + "notification of your decision, including any Decision Notes you "
        + "provided. Please click Deny to confirm, or click Cancel to go back.";
      $mdDialog.show(
        $mdDialog.confirm()
          .parent(angular.element($document[0].querySelector(
            '#popupContainer')))
          .clickOutsideToClose(false)
          .title("Confirm Deny")
          .textContent(message)
          .ariaLabel(message)
          .ok("Deny")
          .cancel('Cancel')
          .multiple(true),
      ).then(function() {
        self.request.statusId = self.requestStatuses.lookup("Denied", "status").id;
        self.save("denied");
      },
      () => {},
      );
    };

    self.cancelRequest = function() {
      if (!self.canCancelRequest()) {
        return;
      }
      self.request.statusId = self.requestStatuses.lookup("Cancelled", "status").id;
      self.save("cancelled");
      $mdDialog.hide();
    };

    self.showConfirm = function(){
      self.submitting = true;
      var confirm = $mdDialog.confirm()
        .title("Confirm Request for: Case " + self.id +  " and " + self.appointment.attorneyName)
        .textContent("Please confirm: Are you sure you want to request access to this appointment? The appointed attorney will receive an automatic email notification of your request, including your notes. Please click Request Access to confirm or click Cancel to go back.")
        .ariaLabel('message')
        .targetEvent(event)
        .ok('Request Access')
        .cancel('Cancel')
        .multiple(true);
      $mdDialog.show(confirm).then(function(){
        self.create();
      }, function(){
        $scope.status = "you have not requested to view";
        self.submitting = false;
      });
    };

    self.revoke = function() {
      if (!self.canRevoke()) {
        return;
      }
      const message =
        "Please confirm: Are you sure you want to revoke this access to this "
        + "appointment? The requester will receive an automatic email "
        + "notification of your decision, including any Decision Notes you "
        + "provided. Please click Revoke to confirm, or click Cancel to go back.";
      $mdDialog.show(
        $mdDialog.confirm()
          .parent(angular.element($document[0].querySelector(
            '#popupContainer')))
          .clickOutsideToClose(false)
          .title("Confirm Revoke")
          .textContent(message)
          .ariaLabel(message)
          .ok("Revoke")
          .cancel('Cancel')
          .multiple(true),
      ).then(function() {
        self.request.revoked = true;
        self.save("revoked");
      },
      () => {},
      );
    };
  },
]);
