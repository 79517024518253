angular.module('CaresApp').service('OcrInvoiceFlagsDialog', [
  '$document',
  '$mdDialog',
  function($document, $mdDialog) {
    const self = this;
    self.show = function() {
      $mdDialog.show({
        controller: 'InvoiceFlagsDialogController',
        templateUrl: 'dialogs/ocr-invoice-flags-dialog/ocr-invoice-flags-dialog.template.html',
        parent: angular.element($document[0].body),
        locals: {},
        clickOutsideToClose: false,
        bindToController: true,
        controllerAs: '$ctrl',
      })
    }
  },
]);

